import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { FaBriefcase } from "react-icons/fa";

import { EntidadContextProvider } from "./../../store/entidad-context";
import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import EntidadesClaves from "../../components/entidades/entidades-claves";
import EntidadesNotificaciones from "../../components/entidades/entidades-notificaciones";
import EntidadesForm from "../../components/entidades/entidades-form";
import EntidadesMatriz from "../../components/entidades/entidades-matriz";
import EntidadesTerminos from "../../components/entidades/entidades-terminos";
import EntidadesValidaciones from "./../../components/entidades/entidades-validaciones";
import EntidadesSmtp from "../../components/entidades/entidades-smtp";
import EntidadesEstilos from "../../components/entidades/entidades-estilos";

const EntidadesDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Crear"
      : hasPermission(actions.EntidadesEditar)
        ? "Editar"
        : "Ver";

  const title = `${action} Entidad`;
  const icon = <FaBriefcase />;
  const breadcrumb = [
    { title: "Entidades", url: "/entidades" },
    { title: action, url: `/entidades/${id}` },
  ];

  return (
    <EntidadContextProvider>
      <Row gutter={[20]}>
        <Col xs={24} lg={id === "nuevo" ? 24 : 16}>
          <Card breadcrumb={breadcrumb}>
            <Header title={title} icon={icon} />
            <EntidadesForm id={id} />
          </Card>
        </Col>

        {id !== "nuevo" && (
          <React.Fragment>
            <Col xs={24} lg={8}>
              <Card>
                <EntidadesEstilos />
              </Card>
            </Col>

            <Col xs={24} lg={24}>
              <Card>
                <EntidadesValidaciones />
              </Card>
            </Col>

            <Col xs={24} lg={12}>
              <Card>
                <EntidadesMatriz />
              </Card>
            </Col>

            <Col xs={24} lg={12}>
              <Card>
                <EntidadesTerminos />
              </Card>
              <Card>
                <EntidadesNotificaciones />
              </Card>

              <Card>
                <EntidadesSmtp />
              </Card>
            </Col>

          </React.Fragment>
        )}
      </Row>
    </EntidadContextProvider>
  );
};

export default EntidadesDetailPage;
