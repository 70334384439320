import { useContext, useState } from 'react';
import { Button, Col, Form, Input, Row, Switch } from 'antd';

import EntidadContext from '../../store/entidad-context';
import { required, rango, valorPositivo } from './../../services/forms';
import http from '../../services/http';
import { modalSuccess } from '../../services/notifications';
import { actions, hasPermission } from '../../services/security';

import classes from './entidades-validaciones.module.scss';

import icon from './../../assets/images/verified.png';

const EntidadesValidaciones = () => {
  const context = useContext(EntidadContext);
  const disabled = !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);

  const onClickSave = async (values) => {
    setLoading(true);

    const response = await http.patch(
      `entidades/${context.id}/validaciones`,
      values
    );

    setLoading(false);

    if (response) {
      await modalSuccess(
        'Validaciones actualizadas',
        'Los valores de las validaciones se guardaron correctamente.'
      );
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.data}>
          <div className={classes.title}>Validaciones</div>
        </div>
      </div>
      <Form
        layout="vertical"
        form={context.formValidaciones}
        onFinish={onClickSave}
      >
        <Row gutter={16} justify="center">
          <Col xs={24} lg={12}>
            <Form.Item
              label="Límite de Solicitudes"
              name="intentos"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de veces que una persona puede generar solicitudes en un tiempo determinado."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Límite de Horas"
              name="intentosHoras"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de horas en las que se validarán el número de intentos."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Días cache Renaper"
              name="renaperDiasCache"
              rules={[required, valorPositivo]}
              extra="Cantidad de días que durarán los datos almacenados en el cache de Renaper."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Días de espera para crear nueva solicitud"
              name="intentoNuevaSolicitudDias"
              rules={[required, valorPositivo]}
              extra="Cantidad de días de espera luego de una solicitud aprobada."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Intentos Prueba de Vida"
              name="intentosPruebaVida"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de veces que se puede intentar la prueba de vida."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Comparación Facial Amazon (mínimo)"
              name="comparacionFacialMinimo"
              rules={[required, rango(0, 100)]}
              extra="Valor mínimo aceptado al realizar la comparación facial Amazon . Entre 0 y 100."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Scoring Email (mínimo)"
              name="emailScoringMinimo"
              rules={[required, rango(0, 100)]}
              extra="Valor mínimo aceptado al realizar la consulta de scoring del email. Entre 0 y 100."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Intentos OTP Email"
              name="intentosOTPEmail"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de veces que se puede ingresar incorrectamente el código de confirmación de email."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Scoring Teléfono (mínimo)"
              name="telefonoScoringMinimo"
              rules={[required, rango(0, 100)]}
              extra="Valor mínimo aceptado al realizar la consulta de scoring del teléfono. Entre 0 y 100."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Intentos OTP Teléfono"
              name="intentosOTPTelefono"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de veces que se puede ingresar incorrectamente el código de confirmación de teléfono."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Control (Worldsys)"
              name="control"
              extra="Nombre del control configurado del cual se extraen los nombres de las fuentes"
            >
              <Input type="text" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Factor Similitud (Worldsys)"
              name="factorSimilitud"
              rules={[required, rango(1, 100)]}
              extra="Factor de similitud un entero entre 1 y 100"
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Factor Similitud Inverso (Worldsys)"
              name="factorSimilitudInverso"
              rules={[required, rango(1, 100)]}
              extra="Factor de similitud inverso, un entero entre 1 y 100"
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Intentos Enrollment"
              name="intentosEnrollment"
              rules={[required, valorPositivo]}
              extra="Cantidad máxima de veces se intenta registrar una operación de Enrollment."
            >
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} justify="center">
          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Prueba de Vida"
              name="addalia"
            >
              <Switch
                checked={context.addalia}
                onChange={(e) => context.updateAddalia(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>

          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Morfología"
              name="morfologia"
            >
              <Switch
                checked={context.morfologia}
                onChange={(e) => context.updateMorfologia(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item className="centered" label="Consultar Afip" name="afip">
              <Switch
                checked={context.afip}
                onChange={(e) => context.updateAfip(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Nosis"
              name="nosis"
            >
              <Switch
                checked={context.nosis}
                onChange={(e) => context.updateNosis(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item className="centered" label="Consultar Uif" name="uif">
              <Switch
                checked={context.uif}
                onChange={(e) => context.updateUif(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Worldsys"
              name="worldsys"
            >
              <Switch
                checked={context.worldsys}
                onChange={(e) => context.updateWorldsys(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Scoring Teléfono"
              name="telefono"
            >
              <Switch
                checked={context.telefono}
                onChange={(e) => context.updateTelefono(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12} lg={4}>
            <Form.Item
              className="centered"
              label="Consultar Scoring Email"
              name="email"
            >
              <Switch
                checked={context.email}
                onChange={(e) => context.updateEmail(e)}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        {!disabled && (
          <div className="actions">
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && 'Actualizar'}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EntidadesValidaciones;
