import React, { useContext, useRef, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import moment from "moment";

import EntidadContext from "../../store/entidad-context";
import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";

import classes from "./entidades-notificaciones-form.module.scss";
import { FaUpload } from "react-icons/fa";


const EntidadesNotificacionesForm = (props) => {
  const [form] = Form.useForm();
  const { item, onSave, onClose } = props;
  const context = useContext(EntidadContext);

  const fileRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showSubject, setShowSubject] = useState(false);

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
      setShowSubject(item?.asunto);
    }
  }, [item]);

  const onClickSave = async (values) => {
    setLoading(true);

    values.id = item.id;
    values.fecha = moment(new Date()).format("DD/MM/YYYY");
    values.asunto = values.asunto ?? "";
    values.habilitado = item.habilitado;

    const response = await http.patch(`entidades/${context.id}/emails`, values);

    setLoading(false);

    if (response) {
      let nItem = { ...item };
      nItem.valor = values.valor;
      nItem.fecha = values.fecha;
      onSave(nItem);

      onSave(values);

      await modalSuccess(
        "Notificación actualizada",
        "La plantilla de notificación se guardó correctamente."
      );
    }
  };

  const onClickUpload = () => {
    fileRef.current.click();
  }

  const onChangeFile = (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    let reader = new FileReader();
    reader.onload = async () => {
      form.setFieldsValue({ contenido: reader.result?.trim() });
    };

    reader.readAsText(file);
    e.currentTarget.value = "";
  };

  return (
    <Modal
      visible={item}
      width={1000}
      centered
      title="Editar notificación"
      footer={null}
      onCancel={onClose}
    >
      <Form layout="vertical" form={form} onFinish={onClickSave}>
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item label="Tipo" name="tipo" rules={[required]}>
              <Input readOnly />
            </Form.Item>
          </Col>

          {showSubject && <Col xs={24}>
            <Form.Item label="Asunto" name="asunto" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>}

          <Col className={classes.contentLabel}>
            <Row>
              <Col xs={20} className="ant-form-item-label">
                <label>Contenido</label>
              </Col>
              <Col xs={4} className={classes.upload}>
                <Button className={classes.button} icon={<FaUpload />} type="text" onClick={onClickUpload}>
                  Subir plantilla
                </Button>
                <input type="file" ref={fileRef} accept=".html" onChange={onChangeFile} />
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Form.Item name="contenido" rules={[required]}>
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>

        </Row>
        <div className="actions">
          <Button type="text" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Editar"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EntidadesNotificacionesForm;
