export const getEntidad = () => {
  const serialized = sessionStorage.getItem("entidad");

  if (serialized === null) {
    return undefined;
  }

  return JSON.parse(serialized);
};

export const getToken = () => {
  return sessionStorage.getItem("token") ?? "";
};

export const actions = {
  SolicitudesVer: 1000,
  SolicitudesEditar: 1001,
  EntidadesVer: 2000,
  EntidadesCrear: 2001,
  EntidadesEditar: 2002,
  EntidadesEliminar: 2003,
  ListaNegraVer: 3000,
  ListaNegraCrear: 3001,
  ListaNegraEditar: 3002,
  ListaNegraEliminar: 3003,
  ParametrosVer: 4000,
  ParametrosEditar: 4002,
  GestoresVer: 12000,
  GestoresCrear: 12001,
  GestoresEditar: 12002,
  GestoresEliminar: 12003,
  PerfilesVer: 5000,
  PerfilesCrear: 5001,
  PerfilesEditar: 5002,
  PerfilesEliminar: 5003,
  UsuariosVer: 6000,
  UsuariosCrear: 6001,
  UsuariosEditar: 6002,
  UsuariosEliminar: 6003,
  AuditoriasVer: 7000,
  ListaBlancaVer: 8000,
  ListaBlancaCrear: 8001,
  ListaBlancaEditar: 8002,
  ListaBlancaEliminar: 8003,
};

export const hasPermission = (action) => {
  const serialized = sessionStorage.getItem("entidad");

  if (serialized === null) {
    return false;
  }

  const entidad = JSON.parse(serialized);

  return entidad.permisos.indexOf(action) > -1;
};
