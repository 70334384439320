import { Col, Row } from "antd";

import ResetPasswordForm from "./../../components/auth/reset-password-form";

import image from "./../../assets/images/reset.svg";
import logo from "./../../assets/images/logo.png";

import classes from "./ResetPassword.module.scss";

const ResetPasswordPage = () => {
  return (
    <div className={classes.app}>
      <div className={classes.formBackground}>
        <ResetPasswordForm />
      </div>
    </div >
  );
};

export default ResetPasswordPage;
