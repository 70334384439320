import { useEffect } from "react";
import { Col, Row } from "antd";

import { getToken } from "../../services/security";

import LoginForm from "../../components/auth/login-form";

import image from "./../../assets/images/login.svg";
import logo from "./../../assets/images/logo.png";

import classes from "./Login.module.scss";

const LoginPage = () => {
  useEffect(() => {
    const token = getToken();

    if (token) {
      window.location.replace("/");
    }
  }, []);

  return (
    <div className={classes.app}>
      <div className={classes.formBackground}>
        <LoginForm />
      </div>
    </div >

  );
};

export default LoginPage;
