import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import InputMask from "react-input-mask";
import jwt from "jwt-decode";

import EntidadContext from "../../store/entidad-context";
import { guid, required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const EntidadesForm = (props) => {
  let navigate = useNavigate();
  const context = useContext(EntidadContext);

  const { id } = props;

  const disabled =
    id === "nuevo"
      ? !hasPermission(actions.EntidadesCrear)
      : !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      context.updateId(id);

      const response = await http.get(`entidades/${id}`);
      if (response) {
        const data = response.data;
        data.color = data.color ?? "#FFFFFF";

        context.form.setFieldsValue(data);
        context.formValidaciones.setFieldsValue(data);
        context.formMatriz.setFieldsValue(data);
        context.formSmtp.setFieldsValue(data);
        context.formEstilos.setFieldsValue(data);

        context.updateTerminos(
          data.terminosNombre,
          data.terminosFecha,
          data.terminosTamanio
        );

        context.updateLogo(data.logo);
        context.updateBackground(data.background);
        context.updateMorfologia(data.morfologia);
        context.updateAddalia(data.addalia);
        context.updateAfip(data.afip);
        context.updateNosis(data.nosis);
        context.updateTelefono(data.telefono);
        context.updateEmail(data.email);
        context.updateUif(data.uif);
        context.updateWorldsys(data.worldsys);

        context.updateClaves(data.claves);
        context.updateNotificaciones(data.notificaciones);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate("/entidades");
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const token = jwt(sessionStorage.getItem("token"));
    values.usuarioId = token.id;

    const response = context.id
      ? await http.put(`entidades/${context.id}`, values)
      : await http.post("entidades", values);

    setLoading(false);

    if (response) {
      if (id === "nuevo") {
        await modalSuccess(
          "Entidad creada",
          "La entidad fue creada exitosamente"
        );
        navigate(`/entidades/${response.data.id}`);
      } else {
        await modalSuccess(
          "Entidad actualizada",
          "La entidad fue modificada exitosamente"
        );
        navigate("/entidades");
      }
    }
  };

  return (
    <Form layout="vertical" form={context.form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label="Identificador"
            name="identificador"
            rules={[required]}
          >
            <Input disabled={disabled} />

          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Url Landing" name="urlLanding">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Email Agente" name="emailAgente" >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Host Api" name="hostApi">
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

      </Row>

      <br />
      <br />
      <br />

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && id === "nuevo" && "Guardar"}
            {!loading && id !== "nuevo" && "Actualizar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default EntidadesForm;
