import { createContext, useState } from "react";
import { Form } from "antd";

const EntidadContext = createContext({
  id: null,
  form: {},
  formValidaciones: {},
  formMatriz: {},
  formSmtp: {},
  formEstilos: {},

  morfologia: true,
  addalia: true,
  afip: true,
  nosis: true,
  uif: true,
  worldsys: true,
  telefono: true,
  email: true,
  terminos: {},
  terminosBanco: {},
  terminosCuentaComitente: {},
  terminosJubilo: {},
  logo: null,
  background: null,

  notificaciones: [],
  claves: [],

  updateId: (value) => { },
  updateMorfologia: (value) => { },
  updateAddalia: (value) => { },
  updateTelefono: (value) => { },
  updateEmail: (value) => { },
  updateAfip: (value) => { },
  updateNosis: (value) => { },
  updateUif: (value) => { },
  updateWorldsys: (value) => { },
  updateTerminos: (name, date, size) => { },
  updateLogo: (value) => { },
  updateBackground: (value) => { },
  updateNotificaciones: (list) => { },
  updateClaves: (list) => { },
});

export function EntidadContextProvider(props) {
  // State

  const [form] = Form.useForm();
  const [formValidaciones] = Form.useForm();
  const [formMatriz] = Form.useForm();
  const [formSmtp] = Form.useForm();
  const [formEstilos] = Form.useForm();

  const [id, setId] = useState();
  const [morfologia, setMorfologia] = useState(true);
  const [addalia, setAddalia] = useState(true);
  const [telefono, setTelefono] = useState(true);
  const [email, setEmail] = useState(true);
  const [afip, setAfip] = useState(true);
  const [nosis, setNosis] = useState(true);
  const [uif, setUif] = useState(true);
  const [worldsys, setWorldsys] = useState(true);
  const [terminos, setTerminos] = useState();
  const [logo, setLogo] = useState();
  const [background, setBackground] = useState();

  const [notificaciones, setNotificaciones] = useState([]);
  const [claves, setClaves] = useState([]);

  // Methods
  const updateId = (value) => {
    setId(value);
  };

  const updateMorfologia = (value) => {
    setMorfologia(value);
  };

  const updateAddalia = (value) => {
    setAddalia(value);
  };
  const updateTelefono = (value) => {
    setTelefono(value);
  };
  const updateEmail = (value) => {
    setEmail(value);
  };

  const updateAfip = (value) => {
    setAfip(value);
  };

  const updateNosis = (value) => {
    setNosis(value);
  };

  const updateUif = (value) => {
    setUif(value);
  };

  const updateWorldsys = (value) => {
    setWorldsys(value);
  };

  const updateTerminos = (name, date, size) => {
    setTerminos({ name, date, size });
  };


  const updateLogo = (value) => {
    setLogo(value);
  };

  const updateBackground = (value) => {
    setBackground(value);
  };

  const updateNotificaciones = (list) => {
    setNotificaciones(list);
  };

  const updateClaves = (list) => {
    setClaves(list);
  };



  const context = {
    id: id,
    form: form,
    formValidaciones: formValidaciones,
    formMatriz: formMatriz,
    formSmtp: formSmtp,
    formEstilos: formEstilos,
    morfologia: morfologia,
    addalia: addalia,
    telefono: telefono,
    email: email,
    afip: afip,
    nosis: nosis,
    uif: uif,
    worldsys: worldsys,
    terminos: terminos,
    logo: logo,
    background: background,
    notificaciones: notificaciones,
    claves: claves,
    updateId: updateId,
    updateMorfologia: updateMorfologia,
    updateAddalia: updateAddalia,
    updateTelefono: updateTelefono,
    updateEmail: updateEmail,
    updateAfip: updateAfip,
    updateNosis: updateNosis,
    updateUif: updateUif,
    updateWorldsys: updateWorldsys,
    updateTerminos: updateTerminos,
    updateLogo: updateLogo,
    updateBackground: updateBackground,
    updateNotificaciones: updateNotificaciones,
    updateClaves: updateClaves,
  };

  return (
    <EntidadContext.Provider value={context}>
      {props.children}
    </EntidadContext.Provider>
  );
}

export default EntidadContext;
