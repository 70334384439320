import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { FiRepeat, FiLogOut } from "react-icons/fi";
import jwt from "jwt-decode";

import { confirm } from "../../services/notifications";
import { getEntidad } from "./../../services/security";

import classes from "./navbar.module.scss";

import icon from "./../../assets/images/user.png";

const Navbar = () => {
  let navigate = useNavigate();

  const [entidad, setEntidad] = useState({});
  const [usuario, setUsuario] = useState();

  useEffect(() => {
    setEntidad(getEntidad());
    const token = jwt(sessionStorage.getItem("token"));
    setUsuario(token.nombre);
  }, []);

  const onClickClearEntidad = () => {
    setEntidad(undefined);
    sessionStorage.removeItem("entidad");
    navigate("/auth/entidades");
  };

  const onClickLogout = async () => {
    const confirmed = await confirm(
      `Cerrar sesión`,
      `¿Esta seguro que desea cerrar la sesión?`
    );

    if (!confirmed) {
      return;
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("entidad");
    window.location.replace("/auth/login");
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key={1} icon={<FiRepeat />} onClick={onClickClearEntidad}>
        Cambiar entidad
      </Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item key={2} icon={<FiLogOut />} onClick={onClickLogout}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={classes.navbar}>
        <Dropdown overlay={menu} placement="bottomRight" trigger="click" arrow>
          <div className={classes.user}>
            <div className={classes.data}>
              {/* <div className={classes.name}>{usuario}</div>
              <div className={classes.company}>{entidad?.nombre}</div> */}
            </div>
            <img className={classes.image} src={icon} alt="user" />
          </div>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
