import { useParams } from "react-router-dom";
import { FaThumbsDown } from "react-icons/fa";

import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import ListaNegraForm from "../../components/lista-negra/lista-negra-form";
import Header from "../../components/ui/header";

const ListaNegraDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Agregar a "
      : hasPermission(actions.ListaNegraEditar)
        ? "Editar"
        : "Ver";

  const title = `${action} Lista Negra`;
  const icon = <FaThumbsDown />;
  const breadcrumb = [
    { title: "Lista Negra", url: "/lista-negra" },
    { title: action, url: `/lista-negra/${id}` },
  ];

  return (
    <Card breadcrumb={breadcrumb}>
      <Header title={title} icon={icon} />
      <ListaNegraForm id={id} />
    </Card>
  );
};

export default ListaNegraDetailPage;
