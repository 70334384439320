import { useContext, useRef, useState } from "react";
import { Dropdown, Menu } from "antd";
import { SpinnerCircular } from "spinners-react";
import { FaDownload, FaEllipsisV, FaUpload } from "react-icons/fa";
import moment from "moment";

import EntidadContext from "../../store/entidad-context";
import { openBase64Pdf, readFileAsBase64 } from "./../../services/files";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import classes from "./entidades-terminos.module.scss";

import icon from "./../../assets/images/clipboard.png";

const EntidadesTerminos = () => {
  const fileRef = useRef(null);
  const context = useContext(EntidadContext);

  const [current, setCurrent] = useState();
  const [loading, setLoading] = useState(false);

  const menu = (type) => (
    <Menu>
      <Menu.Item
        key="1"
        icon={<FaDownload />}
        onClick={() => onClickDownload(type)}
      >
        Descargar
      </Menu.Item>
      {hasPermission(actions.EntidadesEditar) && (
        <Menu.Item
          key="2"
          icon={<FaUpload />}
          onClick={() => onClickUpload(type)}
        >
          Actualizar
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickDownload = async (type) => {
    let url = `${process.env.REACT_APP_API_URL}/entidades/${context.id}/terminos`;


    const response = await http.get(url);
    if (response) {
      const data = response.data;
      openBase64Pdf(data.contenido);
    }
  };

  const onClickUpload = (type) => {
    setCurrent(type);
    fileRef.current.click();
  };

  const onChangeFile = async (e) => {
    const files = e.currentTarget.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const base64 = await readFileAsBase64(file);
    e.target.value = "";

    const data = {
      terminos: base64.split(",")[1],
      terminosNombre: file.name,
      terminosFecha: moment(new Date()).format("DD/MM/YYYY"),
      terminosTamanio: file.size,
      terminoTipo: current,
    };

    setLoading(true);
    const response = await http.patch(`entidades/${context.id}/terminos`, data);
    setLoading(false);

    if (response) {

      context.updateTerminos(
        data.terminosNombre,
        data.terminosFecha,
        data.terminosTamanio
      );

      await modalSuccess(
        "Términos & Condiciones actualizados",
        "Los terminos y condiciones fueron guardados correctamente."
      );
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Terminos & Condiciones</div>
          <div className={classes.description}>
            Actualizá los terminos y condiciones.
          </div>
        </div>
      </div>
      <div className={classes.list}>
        <div className={classes.item}>
          {current === 1 && loading && (
            <SpinnerCircular
              className={classes.loading}
              size="30px"
              thickness={150}
              color="#001529"
              secondaryColor="#eeedfd"
            />
          )}
          {(!loading) && (
            <Dropdown
              overlay={() => menu(1)}
              placement="bottomLeft"
              arrow
              trigger="click"
            >
              <div className={classes.menu}>
                <FaEllipsisV />
              </div>
            </Dropdown>
          )}
          <div className={classes.content}>
            <div className={classes.title}>Generales</div>
            <div className={classes.description}>
              Última actualización: {context.terminos?.date}
            </div>
          </div>
        </div>


      </div>

      <input
        type="file"
        ref={fileRef}
        accept="application/pdf"
        onChange={onChangeFile}
      />
    </div>
  );
};

export default EntidadesTerminos;
