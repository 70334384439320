import {
  FaInbox,
  FaDigitalTachograph,
  FaShieldAlt,
  FaThumbsDown,
  FaThumbsUp,
  FaUser,
} from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";


import { actions, hasPermission } from "./../../services/security";

import InicioSection from "../../components/inicio/inicio-section";

const InicioPage = () => {
  const sections = [
    {
      title: "Inicio",
      links: [
        {
          title: "Solicitudes",
          url: "/solicitudes",
          icon: <FaInbox />,
          visible: hasPermission(actions.SolicitudesVer),
        },
      ],
    },
    {
      title: "Configuración",
      links: [
        {
          title: "Lista Blanca",
          url: "/lista-blanca",
          icon: <FaThumbsUp />,
          visible: hasPermission(actions.ListaBlancaVer),
        },
        {
          title: "Lista Negra",
          url: "/lista-negra",
          icon: <FaThumbsDown />,
          visible: hasPermission(actions.ListaNegraVer),
        },
        {
          title: "Parámetros",
          url: "/parametros",
          icon: <FaDigitalTachograph />,
          visible: hasPermission(actions.ParametrosVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FaShieldAlt />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FaUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ];

  return (
    <div>
      {sections.map(
        (section, index) =>
          section.links.filter((x) => x.visible).length > 0 && (
            <InicioSection key={index} item={section} />
          )
      )}
    </div>
  );
};

export default InicioPage;
