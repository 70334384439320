import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FaEdit, FaSearch, FaTrash, FaUser } from "react-icons/fa";

import { TableContextProvider } from "./../../store/table-context";
import { confirm, modalSuccess } from "../../services/notifications";
import http from "./../../services/http";
import { actions, getEntidad, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import UsuariosEstado from "./../../components/usuarios/usuarios-estado";

const UsuariosListPage = () => {
  let navigate = useNavigate();

  const title = "Usuarios";
  const icon = <FaUser />;
  const breadcrumb = [{ title: "Usuarios", url: "/usuarios" }];

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      type: "input",
      label: "Nombre",
      name: "nombre",
    },
    {
      type: "input",
      label: "Email",
      name: "email",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "1", text: "Habilitado" },
        { value: "0", text: "Deshabilitado" },
      ],
    },
  ]);

  const columns = [
    { title: "Nombre", property: "nombre", sortable: true },
    { title: "Email", property: "email", sortable: true },
    {
      title: "Estado",
      property: "habilitado",
      sortable: false,
      render: (item) => <UsuariosEstado estado={item.habilitado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      {!hasPermission(actions.UsuariosEditar) && (
        <Menu.Item
          key="1"
          icon={<FaSearch />}
          onClick={() => onClickEdit(item)}
        >
          Ver
        </Menu.Item>
      )}
      {hasPermission(actions.UsuariosEditar) && (
        <Menu.Item key="1" icon={<FaEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {hasPermission(actions.UsuariosEliminar) && (
        <Menu.Item
          key="2"
          icon={<FaTrash />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/usuarios/nuevo");
  };

  const onClickEdit = (item) => {
    navigate(`/usuarios/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const entidad = getEntidad();

    const confirmed = await confirm(
      "Eliminar usuario",
      `¿Esta seguro que desea eliminar al usuario ${item.nombre} de esta entidad?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(
      `usuarios/${item.id}?entidad=${entidad.id}`
    );
    if (response) {
      await modalSuccess(
        "Usuario eliminado",
        "El usuario fue eliminado exitosamente de la entidad"
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Usuario",
      text: "Nueva",
      type: "primary",
      onClick: onClickAdd,
      visible: hasPermission(actions.UsuariosCrear),
    },
  ];

  return (
    <TableContextProvider>
      <Card breadcrumb={breadcrumb}>
        <Header
          title={title}
          icon={icon}

          showFilters
          buttons={buttons}
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="table-usuarios"
          columns={columns}
          menu={menu}
          url="/usuarios"
          orderBy="nombre"
          orderDirection="ascending"
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default UsuariosListPage;
