import { useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import { actions, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import UsuariosForm from "./../../components/usuarios/usuarios-form";
import Header from "./../../components/ui/header";

const UsuariosDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Crear"
      : hasPermission(actions.UsuariosEditar)
        ? "Editar"
        : "Ver";

  const title = `${action} Usuario`;
  const icon = <FaUser />;
  const breadcrumb = [
    { title: "Usuarios", url: "/usuarios" },
    { title: action, url: `/usuarios/${id}` },
  ];

  return (
    <Card breadcrumb={breadcrumb}>
      <Header title={title} icon={icon} />
      <UsuariosForm id={id} />
    </Card>
  );
};

export default UsuariosDetailPage;
