import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { FaChevronsRight, FaHome, FaSearch } from "react-icons/fa";

import TableContext from "../../store/table-context";

import classes from "./header.module.scss";

const Header = (props) => {
  const { title, icon, showFilters, buttons, isFilter } = props;
  const context = useContext(TableContext);

  const onClickFilters = () => {
    context.updateFiltersVisible(true);
  };

  return (
    <div className={classes.header}>
      <div className={classes.data}>
        <div className={classes.info}>

          <div className={classes.title}>{title}</div>
        </div>
      </div>
      <div className={classes.buttons}>
        {showFilters && (
          <Tooltip title="Filtros">
            <Button
              type="text"
              size="large"
              onClick={onClickFilters}
              className={isFilter ? classes.buttonsFilter : ''}
            >
              Buscar
            </Button>
          </Tooltip>
        )}

        {buttons &&
          buttons.map(
            (item, index) =>
              item.visible && (
                <Tooltip key={index} title={item.title}>
                  <Button
                    type={item.type}
                    size="large"
                    loading={item.loading}
                    onClick={() => item.onClick({ filters: context.filters })}
                  >
                    {item.loading ? "" : item.text}
                  </Button>
                </Tooltip>
              )
          )}
      </div>
    </div>
  );
};

export default Header;
