import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FaHome,
  FaInbox,
  FaDigitalTachograph,
  FaShieldAlt,
  FaBriefcase,
  FaUser,
  FaThumbsUp,
  FaThumbsDown
} from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";



import SimpleBar from "simplebar-react";

import { actions, hasPermission } from "./../../services/security";
import { Layout, Menu } from 'antd';

import classes from "./sidebar.module.scss";
import logo from "../../assets/images/logo.png";

const Sidebar = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [sections, setSections] = useState([
    {
      title: "",
      links: [
        {
          title: "Inicio",
          url: "/",
          icon: <FaHome />,
          visible: true,
        },
        {
          title: "Solicitudes",
          url: "/solicitudes",
          icon: <FaInbox />,
          visible: hasPermission(actions.SolicitudesVer),
        },


      ],
    },
    {
      title: "Configuración",
      links: [
        {
          title: "Lista Blanca",
          url: "/lista-blanca",
          icon: <FaThumbsUp />,
          visible: hasPermission(actions.ListaBlancaVer),
        },
        {
          title: "Lista Negra",
          url: "/lista-negra",
          icon: <FaThumbsDown />,
          visible: hasPermission(actions.ListaNegraVer),
        },
        {
          title: "Parámetros",
          url: "/parametros",
          icon: <FaDigitalTachograph />,
          visible: hasPermission(actions.ParametrosVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FaShieldAlt />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FaUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ]);

  const version = localStorage.getItem("version");

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const location = useLocation();

  return (
    <Sider
      width={250}
      collapsible
      theme="dark"
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      className={classes.sidebar}
    >
      <Header
        style={{
          padding: 0,
          background: "none",
        }}
      >
        <img
          className={`${classes.logo} ${collapsed ? classes.collapsedLogo : ""}`}
          src={logo}
          alt="logo"
        />



      </Header>
      <div className={classes.brand}>

        <div className={classes.collapseButton} onClick={toggleCollapse}>
          {/* Your collapse/expand icon here */}
        </div>
      </div>
      <div className={`${classes.version} ${collapsed ? classes.versionRes : ""}`}></div>
      <h2 className={classes.name}>Onboarding</h2>

      <SimpleBar style={{ maxHeight: "calc(90vh - 115px)" }}>
        <Menu theme="dark" mode="inline">
          {sections.map((section, sectionIndex) => (
            section.links.some((link) => link.visible) && (
              <Menu.ItemGroup
                key={`section-${sectionIndex}`}
                title={section.title}
                icon={section.icon}
              >
                {section.links.map((link, linkIndex) => (
                  link.visible && (
                    <Menu.Item
                      key={`link-${sectionIndex}-${linkIndex}`}
                      className={location.pathname === link.url ? `${classes.activeLink}` : ""}
                    >
                      <NavLink to={link.url} className={classes.link}>
                        {link.icon && <span className="anticon">{link.icon}</span>}
                        <span>{link.title}</span>
                      </NavLink>
                    </Menu.Item>
                  )
                ))}
              </Menu.ItemGroup>
            )
          ))}
        </Menu>
      </SimpleBar>
    </Sider>
  );
};

export default Sidebar;