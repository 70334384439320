import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Tabs } from "antd";

import { SolicitudContextProvider } from "./../../store/solicitud-context";

import Card from "../../components/ui/card";
import SolicitudesForm from "./../../components/solicitudes/solicitudes-form";
import SolicitudesPersona from "../../components/solicitudes/solicitudes-persona";
import SolicitudesValidaciones from "../../components/solicitudes/solicitudes-validaciones";
import SolicitudesDocumento from "./../../components/solicitudes/solicitudes-documento";
import SolicitudesDomicilio from "./../../components/solicitudes/solicitudes-domicilio";
import SolicitudesContacto from "./../../components/solicitudes/solicitudes-contacto";
import SolicitudesHistorial from "./../../components/solicitudes/solicitudes-historial";
import SolicitudesTimeLine from "./../../components/solicitudes/solicitudes-timeline";
import SolicitudesArchivos from "./../../components/solicitudes/solicitudes-archivos";
import SolicitudesJson from "./../../components/solicitudes/solicitudes-json";


const SolicitudesDetailPage = () => {
  const { id } = useParams();

  const title = `Solicitud`;
  const breadcrumb = [
    { title: "Solicitudes", url: "/solicitudes" },
    { title: "Ver", url: `/solicitudes/${id}` },
  ];

  return (
    <SolicitudContextProvider>
      <Row gutter={[24]}>

        <Col xs={6} lg={6}>
          <Card breadcrumb={breadcrumb}  >
            <SolicitudesForm id={id} />
          </Card>
        </Col>

        <Col xs={18} lg={18} style={{ paddingTop: '32px' }}>
          <Tabs
            tabPosition={'left'}
            items={[

              {
                label: `Documentación`,
                key: 'Documentacion',
                children:
                  <Card>
                    <SolicitudesDocumento />
                  </Card>,
              }, {
                label: `Persona`,
                key: 'Persona',
                children:
                  <Card>
                    <SolicitudesPersona />
                  </Card>,
              },
              {
                label: `Domicilio`,
                key: 'Domicilio',
                children:
                  <Card>
                    <SolicitudesDomicilio />
                  </Card>,
              },
              {
                label: `Contacto`,
                key: 'Contacto',
                children:
                  <Card>
                    <SolicitudesContacto />
                  </Card>,
              },
              {
                label: `Historico`,
                key: 'Historico',
                children:
                  <Card>
                    <SolicitudesHistorial />
                  </Card>,
              },

              {
                label: `Validaciones`,
                key: 'Validaciones',
                children:
                  <Card>
                    <SolicitudesValidaciones />
                  </Card>,
              },
              {
                label: `Archivos`,
                key: 'Archivos',
                children:
                  <Card>
                    <SolicitudesArchivos />
                  </Card>,
              },
              {
                label: `Servicios`,
                key: 'Servicios',
                children:
                  <Card>
                    <SolicitudesJson />
                  </Card>,
              },
              {
                label: `Linea de tiempo`,
                key: 'TimeLine',
                children:
                  <Card>
                    <SolicitudesTimeLine />
                  </Card>,
              }
            ]}
          />


          {/* <Col xs={24} lg={24}>
            <Card>
              <SolicitudesJson />
            </Card>
          </Col> */}
        </Col>


      </Row>
    </SolicitudContextProvider >
  )
}

export default SolicitudesDetailPage;
