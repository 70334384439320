import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FaEdit,
  FaSearch,
  FaThumbsDown,
  FaTrash,
} from "react-icons/fa";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";

import { TableContextProvider } from "../../store/table-context";
import { confirm, modalSuccess } from "../../services/notifications";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

const ListaNegraListPage = () => {
  let navigate = useNavigate();

  const title = "Lista Negra";
  const icon = <FaThumbsDown />;
  const breadcrumb = [{ title: "Lista Negra", url: "/lista-negra" }];

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      type: "select",
      label: "Tipo",
      name: "tipo",
      values: [],
    },
    {
      type: "input",
      label: "Valor",
      name: "valor",
    },
  ]);

  const columns = [
    { title: "Tipo", property: "tipo", sortable: true },
    { title: "Valor", property: "valor", sortable: true },
  ];

  const menu = (item) => (
    <Menu>
      {!hasPermission(actions.ListaNegraEditar) && (
        <Menu.Item
          key="1"
          icon={<FaSearch />}
          onClick={() => onClickEdit(item)}
        >
          Ver
        </Menu.Item>
      )}
      {hasPermission(actions.ListaNegraEditar) && (
        <Menu.Item key="1" icon={<FaEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {hasPermission(actions.ListaNegraEliminar) && (
        <Menu.Item
          key="2"
          icon={<FaTrash />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  useEffect(() => {
    async function getData() {
      const response = await http.get("lista-negra/tipos");

      if (response) {
        let data = response.data;
        data = data.map((item) => {
          return {
            value: item.id,
            text: item.nombre,
          };
        });

        let nFilters = [...filters];
        nFilters[0] = { ...nFilters[0], values: data };

        setFilters(nFilters);
      }
    }

    getData();
  }, []);

  const onClickAdd = () => {
    navigate("/lista-negra/nuevo");
  };

  const onClickEdit = (item) => {
    navigate(`/lista-negra/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      `Eliminar de la lista negra`,
      `¿Esta seguro que desea eliminar de la lista negra el registro ${item.valor}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`lista-negra/${item.id}`);
    if (response) {
      await modalSuccess(
        `Registro eliminado`,
        `El registro fue eliminado exitosamente`
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar a lista negra",
      text: "Nueva",
      type: "primary",
      onClick: onClickAdd,
      visible: hasPermission(actions.ListaNegraCrear),
    },
  ];

  return (
    <TableContextProvider>
      <Card breadcrumb={breadcrumb}>
        <Header
          title={title}
          icon={icon}
          showFilters
          buttons={buttons}
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="table-lista-negra"
          columns={columns}
          menu={menu}
          url="/lista-negra"
          orderBy="nombre"
          orderDirection="ascending"
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default ListaNegraListPage;
